import React from "react"

import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import RibbonText from "../../components/ui/RibbonText"
import ContactBanner from "../../components/ContactBanner"
import Seo from "../../components/Seo"

export default function AboutUs() {
  return (
    <Layout>
      <Seo title="About Us" />
      <div className="p-4 pt-0 flex flex-col sm:flex-row items-center">
        <div className="lg:w-2/5 self-start sm:h-full overflow-hidden pb-4">
          <StaticImage
            alt="Family photo of the Manciamelis in their backyard."
            className="h-95vh sm:h-[90vh] mdh-95vh w-full flex-grow-0"
            src="../../images/family-photo.jpg"
          />
        </div>
        <div className="mt-4 sm:mt-0 sm:p-8 sm:w-3/5 flex flex-col">
          <h1>Our Story</h1>
          <RibbonText
            justify="left"
            text="A better way to"
            highlighted="find a builder"
            className="mt-6 md:hidden lg:inline lg:mt-12"
          />
          <div className="sm:hidden lg:inline lg:w-2/3 my-8 lg:my-16 space-y-4 self-center">
            <p>
              Growing up, Lynette’s family had a building company, so discussing
              property and construction was dinner table conversation. Lynette
              and her husband John had the luxury of being able to call on
              Lynette’s father and his extended network of contacts to assist
              with renovating and building. However, when Lynette’s father and
              his contacts all started retiring, they had no one to help them to
              take on the latest massive renovation plans for their home. Which
              left them like most Australians now having to find a reputable
              builder. They set out like everyone else to find a builder via
              word of mouth and Google. They found a builder and the result was
              good however it was over budget and over time. Reflecting on the
              anxiety of this experience, they knew there had to be a better way
              to find a builder.
            </p>
            <p>
              They thought it would be great to have a brokering service in the
              building industry. This is where Builder Finders was born. They
              had a dream of creating a service where an independent Builder
              Finder does the running around. Part of their dream was to
              mitigate as much risk as possible so you can find a builder that
              you can trust. A builder that is the right fit for your building
              needs, whilst saving you an enormous amount of time and money. The
              company is driven with a passionate desire to match the right
              builder with your unique property building needs.
            </p>
          </div>
          <h2 className="sm:hidden lg:inline lg:w-1/2 self-end sm:text-right">
            We are driven to match the right builder with your unique building
            needs.
          </h2>
        </div>
      </div>
      <div className="container px-4 hidden lg:hidden sm:flex flex-col">
        <div className="space-y-2 self-center">
          <p>
            Growing up, Lynette’s family had a building company, so discussing
            property and construction was dinner table conversation. Lynette and
            her husband John had the luxury of being able to call on Lynette’s
            father and his extended network of contacts to assist with
            renovating and building. However, when Lynette’s father and his
            contacts all started retiring, they had no one to help them to take
            on the latest massive renovation plans for their home. Which left
            them like most Australians now having to find a reputable builder.
            They set out like everyone else to find a builder via word of mouth
            and Google. They found a builder and the result was good however it
            was over budget and over time. Reflecting on the anxiety of this
            experience, they knew there had to be a better way to find a
            builder.
          </p>
          <p>
            They thought it would be great to have a brokering service in the
            building industry. This is where Builder Finders was born. They had
            a dream of creating a service where an independent Builder Finder
            does the running around. Part of their dream was to mitigate as much
            risk as possible so you can find a builder that you can trust. A
            builder that is the right fit for your building needs, whilst saving
            you an enormous amount of time and money. The company is driven with
            a passionate desire to match the right builder with your unique
            property building needs.
          </p>
        </div>
        <h2 className="sm:w-1/2 mt-4 self-end sm:text-right">
          We are driven to match the right builder with your unique building
          needs.
        </h2>
      </div>
      <ContactBanner />
    </Layout>
  )
}
